import { inject, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { QuoteToolPreselectedModes as PreselectedModes } from '@klg/shared/utils/enums';
import {
  completeEslQuoteOutput,
  hasEnoughDataToGetQuote,
  mapQuoteSlotsToQuoteEmailInputApiDto,
  mapQuoteToQuotePaymentApiDto,
  QuoteService,
} from '@klg/quote-tool/shared/data-access/quote';

import {
  LeadEntityCodeAndNames,
  QuoteInput,
  QuoteLeadInputV4,
  QuoteLeadOriginEnum,
  QuoteOutput,
  QuoteOutputSlots,
  QuoteSlots,
  QuoteToolFullState,
  ResidenceData,
  SlottedArray,
  SlottedObject,
  SlottedObservableArray,
  StudentDetails,
} from '@klg/quote-tool/shared/types/quote';
import { AgeGroup, AgeGroups, FormType, NavigationCallback, ProductPackageEnum, ROUTE_IDS, StepDefinition } from '@klg/quote-tool/shared/types';
import {
  SessionStorageService,
  STORAGE_KEY_QT_ACTIVE_SLOT,
  STORAGE_KEY_QT_ACTIVE_STEP_ID,
  STORAGE_KEY_QT_AGE_GROUP,
  STORAGE_KEY_QT_MODE,
  STORAGE_KEY_QT_PREFILLED_ENROLMENT_ID,
  STORAGE_KEY_QT_PREFILLED_KLG_TOKEN,
  STORAGE_KEY_QT_PRESELECTED_MODE,
  STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY,
  STORAGE_KEY_QT_RESIDENCE_DATA,
  STORAGE_KEY_QT_SLOTS,
  STORAGE_KEY_QT_STUDENT_DETAILS,
} from '@klg/shared/storage';
import { CountryService } from '@klg/quote-tool/shared/data-access/destination';
import { CurrencyCode } from '@klg/currency/shared/model/currency.model';
import { Language, LanguageService } from '@klg/language';
import { CourseService, PackageService } from '@klg/quote-tool/shared/data-access/course';
import { PackageContext } from '@klg/quote-tool/shared/types/course';
import { QuoteCampService, QuoteSchoolService } from '@klg/quote-tool/shared/data-access/school';
import { ConfigurationService } from '@klg/shared/app-config';
import { getCompany, getConfiguration } from '@klg/shared/tokens';
import { CodeAndName, COMPANIES } from '@klg/shared/types';
import { deepClone, getDateStringFromDate, isDeepEqual, valueIsNullOrUndefined } from '@klg/shared/utils';
import { BehaviorSubject, combineLatest, isObservable, Observable, of, pairwise, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, first, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/analytics.service';
import { HostDataReaderService } from '../host-data-reader/host-data-reader.service';
import { workflows } from './steps.constants';
import { ResidenceCountryService } from '@klg/shared/data-access/destination';
import { getLocaleCountryCode } from '@klg/shared/i18n';
import { INITIAL_ROUTE_PER_AGE_GROUP, QuoteToolModes, TARGET_ROUTE_PER_PRESELECTED_MODE } from '@klg/quote-tool/shared/routing';
import { QuoteToolStore } from '@klg/quote-tool/shared/store';

const QUOTE_STEP_DATA_MAP: Record<ROUTE_IDS, (keyof QuoteInput)[]> = {
  [ROUTE_IDS.RESIDENCE_COUNTRY]: [],
  [ROUTE_IDS.AGE]: [],
  [ROUTE_IDS.JUNIOR_BASE]: ['tuitionWeeks', 'camp', 'countryCode', 'startDate', 'endDate'],
  [ROUTE_IDS.JUNIOR_COURSE]: ['courseType', 'course'],
  [ROUTE_IDS.JUNIOR_EXTRAS]: ['premiumActivities', 'privateLessons'],
  [ROUTE_IDS.ADULT_BASE]: ['tuitionWeeks', 'school', 'countryCode', 'startDate', 'endDate'],
  [ROUTE_IDS.ADULT_COURSE]: ['courseType', 'course'],
  [ROUTE_IDS.ADULT_EXTRAS]: ['accommodation', 'insurance', 'transfer', 'virtualInternship'],
  [ROUTE_IDS.QUOTE]: [],
  [ROUTE_IDS.ENROLMENT]: [],
  [ROUTE_IDS.ENROLMENT_PAYMENT]: [],
  [ROUTE_IDS.ENROLMENT_PAYMENT_CONFIRMATION]: [],
  [ROUTE_IDS.REGISTRATION]: [],
  [ROUTE_IDS.DELIVERY]: [],
  [ROUTE_IDS.ONLINE_BASE]: [],
  [ROUTE_IDS.FORM_ROOT]: [],
  [ROUTE_IDS.QUOTE_FORM]: [],
  [ROUTE_IDS.BOOKING_FORM]: [],
  [ROUTE_IDS.SEND_QUOTE_TO_EMAIL]: [],
  [ROUTE_IDS.THANK_YOU]: [],
  [ROUTE_IDS.QUOTE_ERROR]: [],
};

@Injectable({
  providedIn: 'root',
})
export class StepService implements OnDestroy {
  public quoteRequest$: Observable<QuoteInput>;
  public quoteOutput$: Observable<QuoteOutput>;
  private _calculatingQuote$ = new BehaviorSubject<boolean>(false);
  public calculatingQuote$: Observable<boolean>;
  public currentStepDefinition$ = new BehaviorSubject<StepDefinition>(null);
  public activeQuoteSlot$: Observable<keyof QuoteSlots>;
  public _studentDetails$ = new BehaviorSubject<Partial<StudentDetails>>({});
  public studentDetails$: Observable<Partial<StudentDetails>>;
  private _activeSlot$ = new BehaviorSubject<keyof QuoteSlots>(1);
  private quoteSlots: QuoteSlots;
  private subscription = new Subscription();
  private currentStepValid$ = new Subject<Observable<boolean>>();
  private _closeQuoteTool$: Subject<void> = new Subject<void>();
  public closeQuoteTool$: Observable<void>;
  private activeAgeGroup$ = new BehaviorSubject<AgeGroup>(null);
  // That's the best
  private formType$ = new BehaviorSubject<FormType>(null);
  private language: string;
  private flowLanguage: string;
  private _residenceData$ = new BehaviorSubject<ResidenceData>(null);
  public residenceData$: Observable<ResidenceData>;
  private _quoteSummaryCurrency$ = new BehaviorSubject<CurrencyCode>(null);
  public quoteSummaryCurrency$: Observable<CurrencyCode>;
  private readonly marketCountry: string;
  public residenceCurrency: string;
  private readonly company = getCompany();
  private readonly configuration = getConfiguration();
  private areQuoteTypesConsistent$ = new BehaviorSubject<boolean>(true);
  private areQuoteTypesAdultAndOnline$ = new BehaviorSubject<boolean>(false);
  private hasEnforcedTypeConsistency$ = new Subject<void>();

  private readonly quoteToolStore = inject(QuoteToolStore);

  constructor(
    private router: Router,
    private quoteService: QuoteService,
    private analyticsService: AnalyticsService,
    private schoolService: QuoteSchoolService,
    private campService: QuoteCampService,
    private languageService: LanguageService,
    private courseService: CourseService,
    private countryService: CountryService,
    private sessionStorageService: SessionStorageService,
    private hostDataReader: HostDataReaderService,
    private configurationService: ConfigurationService,
    private packageService: PackageService,
    private residenceCountryService: ResidenceCountryService,
    @Inject(LOCALE_ID) locale: string,
    activatedRoute: ActivatedRoute,
  ) {
    this.initCurrentStepDefinition(activatedRoute);
    this.setLanguage(locale);
    this.marketCountry = getLocaleCountryCode();
    this.calculatingQuote$ = this._calculatingQuote$.asObservable();
    this.activeQuoteSlot$ = this._activeSlot$.asObservable();
    this.closeQuoteTool$ = this._closeQuoteTool$.asObservable();
    this.studentDetails$ = this._studentDetails$.asObservable();
    this.residenceData$ = this._residenceData$.asObservable();
    this.quoteSummaryCurrency$ = this._quoteSummaryCurrency$.asObservable();
  }

  public initialize() {
    this.quoteOutput$ = this._activeSlot$.pipe(switchMap((slot) => this.quoteSlots[slot]));
    this.quoteRequest$ = this.quoteOutput$.pipe(map((quoteOutput) => quoteOutput?.input ?? ({} as QuoteInput)));
    const { quoteSlots, activeSlot, studentDetails, ageGroup, residenceData, quoteSummaryCurrency } = this.getDataFromSessionStorage();
    this.initQuoteSlots(quoteSlots);
    this.setActiveSlot(activeSlot);
    this.initAgeGroup(ageGroup);
    this.initQuoteTypeConsistency();
    this.initStudentDetails(studentDetails);
    this.setResidenceData(residenceData);
    this.setQuoteSummaryCurrency(quoteSummaryCurrency ?? residenceData?.residenceCurrency ?? null);
    this.writeChangesToSessionStorage();
  }

  private initAgeGroup(ageGroup: AgeGroup) {
    this.activeAgeGroup$
      .pipe(
        pairwise(),
        filter(([oldAgeGroup, newAgeGroup]) => newAgeGroup !== oldAgeGroup && !valueIsNullOrUndefined(newAgeGroup)),
      )
      .subscribe(([, newAgeGroup]) => this.changeEndpointConfigs(newAgeGroup));

    this.activeAgeGroup$.next(ageGroup);
  }

  private initQuoteTypeConsistency() {
    const quotes$ = this.getSlotsArray$(this.quoteSlots);
    const quoteSlots$ = this.transformSlottedArrayToObservableOfSlotted<QuoteOutput>(quotes$);
    quoteSlots$.pipe(map((quotes) => this.areSlottedQuoteTypesConsistent(quotes))).subscribe(this.areQuoteTypesConsistent$);
  }

  private areSlottedQuoteTypesConsistent(quotes: SlottedObject<QuoteOutput>) {
    return Object.entries(quotes).every(
      ([, quote], index, [[, firstQuote]]) =>
        quote === null ||
        quote?.input?.quoteType === firstQuote?.input?.quoteType ||
        this.areSlottedQuoteTypesAdultAndOnline(quote?.input?.quoteType, firstQuote?.input?.quoteType),
    );
  }

  private areSlottedQuoteTypesAdultAndOnline(quote: string, firstQuote: string) {
    const areAdultAndOnline = (quote === AgeGroups.Online && firstQuote === AgeGroups.Adult) || (quote === AgeGroups.Adult && firstQuote === AgeGroups.Online);
    this.areQuoteTypesAdultAndOnline$.next(areAdultAndOnline);
    return areAdultAndOnline;
  }

  public getLanguageCodeAndName$(quoteInput: QuoteInput): Observable<CodeAndName> {
    return this.languageService.getByCode(quoteInput.languageCode).pipe(map(({ code, name }: Language) => ({ code, name })));
  }

  public areQuoteTypesAdultAndOnline() {
    return this.areQuoteTypesAdultAndOnline$.getValue();
  }

  public getCurrentActiveStep() {
    return this.getActiveStep();
  }

  public areQuoteTypesConsistent() {
    return this.areQuoteTypesConsistent$.getValue();
  }

  public getHasEnforcedConsistency$() {
    return this.hasEnforcedTypeConsistency$.asObservable();
  }

  public clearSessionStorage() {
    const quoteToolStorageDataKeys = [
      STORAGE_KEY_QT_MODE,
      STORAGE_KEY_QT_SLOTS,
      STORAGE_KEY_QT_ACTIVE_STEP_ID,
      STORAGE_KEY_QT_ACTIVE_SLOT,
      STORAGE_KEY_QT_STUDENT_DETAILS,
      STORAGE_KEY_QT_AGE_GROUP,
      STORAGE_KEY_QT_RESIDENCE_DATA,
      STORAGE_KEY_QT_PREFILLED_KLG_TOKEN,
      STORAGE_KEY_QT_PREFILLED_ENROLMENT_ID,
      STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY,
    ];
    quoteToolStorageDataKeys.forEach((key) => this.sessionStorageService.delete(key));
  }

  public cleanSession() {
    this.clearSessionStorage();
    this.resetSlots();
    this.resetStudentDetails();
  }

  public resetQuoteTool() {
    this.resetSlots();
    this.resetStudentDetails();
    this.resetResidenceData();
    this.resetAgeGroup();
    this.resetActiveSlot();
    this.clearSessionStorage();
  }

  public setStepValid(isValid$: Observable<boolean>) {
    this.currentStepValid$.next(isValid$);
  }

  public getCurrentStepValid(): Observable<boolean> {
    return this.currentStepValid$.pipe(switchMap((stepIsValid$) => stepIsValid$));
  }

  public navigateToErrorStep(error: Error) {
    console.error(error);
    const errorStepCallback = this.currentStepDefinition$.getValue().errorStep;
    if (!errorStepCallback) {
      console.warn('Navigation to error step requested, but no transition was declared.');
    }
    this.getFullState()
      .pipe(take(1))
      .subscribe((fullState: QuoteToolFullState) => {
        this.navigateToStep(errorStepCallback(fullState));
      });
  }

  public navigateToPreviousStep() {
    const { previousStep } = this.currentStepDefinition$.getValue();
    this.handleStepNavigation(previousStep);
  }

  public navigateToNextStep() {
    const { nextStep } = this.currentStepDefinition$.getValue();
    this.handleStepNavigation(nextStep);
  }

  private handleStepNavigation(stepCallback: NavigationCallback | undefined) {
    if (!stepCallback) {
      console.warn('Navigation to step requested, but no transition was declared.');
      return;
    }
    this.getFullState()
      .pipe(
        take(1),
        switchMap((fullState: QuoteToolFullState) => {
          const returnValue = stepCallback(fullState);
          return isObservable(returnValue) ? returnValue : of(returnValue);
        }),
      )
      .subscribe((route: ROUTE_IDS) => {
        this.navigateToStep(route);
      });
  }

  public navigateToStep(step: ROUTE_IDS, replaceUrl = true) {
    // TODO: Improve this on KLG-2268
    const parentPath = this.router.url.replace(/^(.+?)\/[^/]+$/, '$1');
    const commands = parentPath && parentPath !== '/' ? [parentPath, step] : [step];
    this.router.navigate(commands, { replaceUrl });
  }

  public navigateToInitialStep(ageGroup: AgeGroup) {
    this.navigateToStep(INITIAL_ROUTE_PER_AGE_GROUP[ageGroup]);
  }

  public setPartialRequest<T extends Partial<QuoteInput>>(partial: T, slot: keyof QuoteSlots = this.getActiveSlot()) {
    const quoteOutput$ = this.quoteSlots[slot];
    const quoteOutput = quoteOutput$.getValue() ?? ({} as QuoteOutput);
    const quoteInput = quoteOutput?.input ?? ({} as QuoteInput);
    quoteOutput$.next({ ...quoteOutput, input: { ...quoteInput, ...partial } });
  }

  public setPartialQuoteOutput<T extends Partial<QuoteOutput>>(partial: T, slot: keyof QuoteSlots = this.getActiveSlot()) {
    const quoteOutput$ = this.quoteSlots[slot];
    const quoteOutput = quoteOutput$.getValue() ?? ({} as QuoteOutput);
    quoteOutput$.next({ ...quoteOutput, ...partial });
  }

  /**
   * Works as setPartialRequests but emits only if the QuoteInput had changed
   */
  public updatePartialRequest<T extends Partial<QuoteInput>>(partial: T, slot: keyof QuoteSlots = this.getActiveSlot()) {
    if (!this.isContentIdentical(partial, slot)) {
      this.setPartialRequest(deepClone(partial), slot);
    }
  }

  public setActiveSlot(slotNumber: keyof QuoteSlots) {
    this._activeSlot$.next(slotNumber);
    this.setPartialRequest({ formType: this.getFormType() });
  }

  public resetSlot(slotNumber: keyof QuoteSlots) {
    const quoteOutput$ = this.quoteSlots[slotNumber];
    quoteOutput$.next(null);
  }

  public resetSlots() {
    const formType = this.getFormType();

    this.resetSlot(1);
    this.resetSlot(2);
    this.resetSlot(3);

    this.setFormType(formType);
  }

  public resetStudentDetails() {
    this._studentDetails$.next({} as StudentDetails);
  }

  public resetResidenceData() {
    this._residenceData$.next({} as ResidenceData);
  }

  public resetAgeGroup() {
    this.activeAgeGroup$.next(null);
  }

  public resetActiveSlot() {
    this._activeSlot$.next(null);
  }

  public getQuoteInputBySlot(slotNumber: keyof QuoteSlots): QuoteInput {
    return deepClone(this.quoteSlots[slotNumber]?.getValue()?.input ?? ({} as QuoteInput));
  }

  public getActiveQuoteInput(): Observable<QuoteInput> {
    return this.activeQuoteSlot$.pipe(map((activeSlot: keyof QuoteSlots) => this.getQuoteInputBySlot(activeSlot)));
  }

  public getActiveSlot(): keyof QuoteSlots {
    return this._activeSlot$.getValue();
  }

  public requestActiveQuote(): Observable<QuoteOutput> {
    return this.quoteOutput$.pipe(
      debounceTime(150),
      tap((quote: QuoteOutput) => {
        if (hasEnoughDataToGetQuote(quote?.input) && !this._calculatingQuote$.getValue()) {
          this._calculatingQuote$.next(true);
        }
      }),
      switchMap((quote: QuoteOutput) =>
        hasEnoughDataToGetQuote(quote?.input)
          ? this.getQuotePrices(quote.input).pipe(
              map(completeEslQuoteOutput),
              catchError(() => of(quote)),
            )
          : of(quote),
      ),
      tap(() => {
        if (this._calculatingQuote$.getValue()) {
          this._calculatingQuote$.next(false);
        }
      }),
    );
  }

  public requestQuoteOutputsFromInputs(): Observable<QuoteOutput[]> {
    if (!this.areQuoteTypesConsistent()) {
      this.hasEnforcedTypeConsistency$.next();
      // Reset the slots on the quote tool store
      this.quoteToolStore.resetSlots$(this.getActiveSlot());
      this.enforceQuoteTypeConsistency();
    }
    const quoteOutputs$: SlottedObservableArray<QuoteOutput> = this.getSlotsArray$(this.quoteSlots);
    return combineLatest(quoteOutputs$).pipe(
      switchMap((quoteOutputs) =>
        combineLatest(
          quoteOutputs.map((quote: QuoteOutput) => {
            if (quote?.input) {
              quote.input.destinationCurrency = quote.input.destinationCurrency || this.getQuoteSummaryCurrency();
            }
            return this.isValidInput(quote?.input)
              ? this.getQuotePrices({
                  ...quote.input,
                  productPackage: this.isJuniors() ? ProductPackageEnum.YOUNG_LEARNER_CAMPS : undefined,
                })
              : of(null);
          }),
        ),
      ),
      first(),
    );
  }

  private enforceQuoteTypeConsistency() {
    this.resetInactiveSlots();
    this.moveActiveSlotToFirst();
  }

  public isJuniors(): boolean {
    return this.getActiveAgeGroup() === 'junior';
  }

  public isDepositEnabled(): boolean {
    return this.isOnlineFlow() ? (this.configuration.QUOTE_TOOL_ONLINE_FLOW_ENABLED as boolean) : (this.configuration.QUOTE_TOOL_ENROLMENT as boolean);
  }

  /**
   * It checks if active group is online
   *
   * @deprecated review the uses in the code to see if we can replace it with  isOnlineFlow$()
   */
  public isOnlineFlow(): boolean {
    return this.getActiveAgeGroup() === AgeGroups.Online;
  }

  /**
   * It takes the active quote input and checks if the quoteType is Online
   */
  isActiveQuoteInputOnline$(): Observable<boolean> {
    return this.getActiveQuoteInput().pipe(map(({ quoteType }) => quoteType === AgeGroups.Online));
  }

  public isJuniors$(): Observable<boolean> {
    return this.activeAgeGroup$.pipe(map((ageGroup) => ageGroup === 'junior'));
  }

  public getQuoteOutputs(): Observable<QuoteOutputSlots> {
    const quoteOutputs$: SlottedObservableArray<QuoteOutput> = this.getSlotsArray$(this.quoteSlots);
    return this.transformSlottedArrayToObservableOfSlotted(quoteOutputs$);
  }

  public setStudentDetails(studentDetails: Partial<StudentDetails>) {
    this._studentDetails$.next({ ...studentDetails });
    if (studentDetails.countryOfResidence) {
      this.setCountryOfResidence(studentDetails.countryOfResidence);
    }
  }

  public setPartialStudentDetails(partialStudentDetails: Partial<StudentDetails>) {
    this._studentDetails$.next({ ...this._studentDetails$.getValue(), ...partialStudentDetails });
    if (partialStudentDetails.countryOfResidence) {
      this.setCountryOfResidence(partialStudentDetails.countryOfResidence);
    }
  }

  public resetStepData(
    startStep: ROUTE_IDS = this.currentStepDefinition$.getValue().stepId,
    endStep?: ROUTE_IDS,
    slot: keyof QuoteSlots = this.getActiveSlot(),
  ) {
    const quoteInput = this.getQuoteInputBySlot(slot);
    if (quoteInput.quoteType && quoteInput.quoteType !== this.getActiveAgeGroup()) {
      Object.entries(quoteInput)
        .filter(([key]) => key !== 'formType')
        .forEach(([key]) => (quoteInput[key] = undefined));
    }
    const startStepIndex = workflows[this.getActiveAgeGroup()][startStep];
    const endStepIndex = endStep && workflows[this.getActiveAgeGroup()][endStep];
    const affectedStepsProps = Object.entries(QUOTE_STEP_DATA_MAP)
      .filter(([key]) =>
        Object.entries(workflows[this.getActiveAgeGroup()])
          .filter(([, index]) => index > startStepIndex && (!endStepIndex || endStepIndex >= index))
          .map(([route]) => route)
          .includes(key),
      )
      .map(([, props]) => props);
    affectedStepsProps.flat().forEach((key: keyof QuoteInput) => {
      quoteInput[key] = undefined as never;
    });
    this.setPartialRequest(quoteInput);
  }

  public isContentIdentical(partialQuote: Partial<QuoteInput>, slot: keyof QuoteSlots = this.getActiveSlot()): boolean {
    const quoteInput = this.getQuoteInputBySlot(slot);
    return Object.entries(partialQuote).every(([key, value]) => isDeepEqual(quoteInput[key], value));
  }

  public closeQuoteTool(): void {
    this._closeQuoteTool$.next();
  }

  /**
   * Returns the first empty slot or undefined if there is none.
   * A slot is empty if it doesn't have any product. This means that
   * the price has not been calculated so the quote in the slot is officially empty until the price
   * is calculated.
   */
  public getEmptySlot(): string {
    const [emptySlot] = Object.entries(this.quoteSlots).find(([, quote]) => !quote.getValue() || !quote.getValue().products) || [];
    return emptySlot;
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(language: string) {
    this.language = language;
    this.configurationService.get(language);
  }

  public getFlowLanguage(): string {
    return this.flowLanguage;
  }

  public setFlowLanguage(language: string) {
    this.flowLanguage = language;
  }

  public sendEnrolmentPaymentInformation() {
    const analytics = this.analyticsService.getQuoteLeadAnalyticsData();
    const formOrigin = this.isOnlineFlow() ? QuoteLeadOriginEnum.ONLINE_COURSES_ENROLMENT : QuoteLeadOriginEnum.ENROLMENT;
    return combineLatest([this.getQuoteInfoForActiveQuote(), this.studentDetails$]).pipe(
      first(),
      map(([[activeQuote, school, language, course], studentDetails]: [[QuoteOutput, CodeAndName, CodeAndName, CodeAndName], StudentDetails]) =>
        mapQuoteToQuotePaymentApiDto(
          activeQuote,
          studentDetails,
          { school, language, course },
          this.language,
          this.getResidenceData(),
          formOrigin,
          analytics,
          this.getQuoteSummaryCurrency(),
        ),
      ),
      switchMap((lead: QuoteLeadInputV4) => this.quoteService.createLeadV4(lead)),
    );
  }

  public sendCommunication() {
    const analytics = this.analyticsService.getQuoteLeadAnalyticsData();
    const slottedArray$ = this.getSlotsArray$(this.quoteSlots);
    return combineLatest(slottedArray$).pipe(
      switchMap((slottedQuotes) =>
        combineLatest(
          slottedQuotes.map((quoteOutput): Observable<LeadEntityCodeAndNames> => {
            if (!quoteOutput || !Object.keys(quoteOutput).length) {
              return of(undefined);
            }

            const quoteInput: QuoteInput = quoteOutput.input;
            const schoolOrCampName$ = this.getSchoolCodeAndName$(quoteInput);
            const languageName$ = this.getLanguageCodeAndName$(quoteInput);
            const courseOrPackageName$ = this.getCourseCodeAndName$(quoteInput);

            return combineLatest([schoolOrCampName$, languageName$, courseOrPackageName$]).pipe(
              map(([school, language, course]: [CodeAndName, CodeAndName, CodeAndName]) => ({ school, language, course })),
            );
          }),
        ).pipe(
          first(),
          map((namesArray: SlottedArray<LeadEntityCodeAndNames>) =>
            mapQuoteSlotsToQuoteEmailInputApiDto(
              slottedQuotes,
              this.getStudentDetailsWizardMode(),
              namesArray,
              this.language,
              this.getResidenceData(),
              analytics,
              this.getQuoteSummaryCurrency(),
            ),
          ),
        ),
      ),
      switchMap((lead: QuoteLeadInputV4) => this.quoteService.createLeadV4(lead)),
      first(),
    );
  }

  public initiateNavigation(desiredStep: ROUTE_IDS) {
    const initStep = this.getInitialStep(desiredStep);
    if (initStep) {
      setTimeout(() => this.navigateToStep(initStep));
    }
  }

  getQtPreSelectedMode(): PreselectedModes {
    return this.sessionStorageService.get(STORAGE_KEY_QT_PRESELECTED_MODE) as PreselectedModes;
  }

  public setStoredMode(mode: QuoteToolModes) {
    this.sessionStorageService.set(STORAGE_KEY_QT_MODE, mode);
  }

  public getStoredMode(): QuoteToolModes {
    return this.sessionStorageService.get<QuoteToolModes>(STORAGE_KEY_QT_MODE);
  }

  public getActiveAgeGroup(): AgeGroup {
    return this.activeAgeGroup$.getValue();
  }

  public setActiveAgeGroup(newAgeGroup: AgeGroup): void {
    if (valueIsNullOrUndefined(newAgeGroup)) {
      return;
    }
    this.activeAgeGroup$.next(newAgeGroup);
  }

  public getFormType(): FormType {
    return this.formType$.getValue();
  }

  public setFormType(formType: FormType): void {
    this.formType$.next(formType);
    this.updatePartialRequest({ formType });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private slottedArrayToSlottedObject<T>(array: SlottedArray<T>): SlottedObject<T> {
    return array.reduce((output, t, i) => ({ ...output, [i + 1]: t }), {} as SlottedObject<T>);
  }

  private initCurrentStepDefinition(activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data),
        map((data) => data.stepDefinition),
        shareReplay(1),
      )
      .subscribe(this.currentStepDefinition$);
  }

  private getSlotsArray$<T>(slots: SlottedObject<Observable<T>>): SlottedObservableArray<T> {
    return Object.entries(slots)
      .sort(([keyA], [keyB]) => +keyA - +keyB)
      .map(([, quote]) => quote) as SlottedObservableArray<T>;
  }

  private transformSlottedArrayToObservableOfSlotted<T>(array: SlottedObservableArray<T>): Observable<SlottedObject<T>> {
    return combineLatest(array).pipe(map((qo: SlottedArray<T>) => this.slottedArrayToSlottedObject<T>(qo), {} as SlottedObject<T>));
  }

  private writeChangesToSessionStorage() {
    const quotes$ = this.getSlotsArray$(this.quoteSlots);
    const quoteSlots$ = this.transformSlottedArrayToObservableOfSlotted<QuoteOutput>(quotes$);
    this.subscription.add(
      combineLatest([
        quoteSlots$,
        this.getStepId$(),
        this.activeQuoteSlot$,
        this.studentDetails$,
        this.activeAgeGroup$,
        this._residenceData$,
        this._quoteSummaryCurrency$,
      ]).subscribe(([quotes, step, slot, studentDetails, ageGroup, residenceData, quoteSummaryCurrency]) => {
        this.sessionStorageService.set(STORAGE_KEY_QT_SLOTS, quotes);
        if (step && step !== ROUTE_IDS.QUOTE_FORM) {
          this.sessionStorageService.set(STORAGE_KEY_QT_ACTIVE_STEP_ID, step);
        }
        this.sessionStorageService.set(STORAGE_KEY_QT_ACTIVE_SLOT, slot);
        this.sessionStorageService.set(STORAGE_KEY_QT_STUDENT_DETAILS, studentDetails);
        this.sessionStorageService.set(STORAGE_KEY_QT_AGE_GROUP, ageGroup);
        this.sessionStorageService.set(STORAGE_KEY_QT_RESIDENCE_DATA, residenceData);
        this.sessionStorageService.set(STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY, quoteSummaryCurrency);
      }),
    );
  }

  private getDataFromSessionStorage() {
    const quoteSlots = this.sessionStorageService.get<QuoteOutputSlots>(STORAGE_KEY_QT_SLOTS) ?? ({} as QuoteOutputSlots);
    const activeStep = this.getActiveStep();
    const activeSlot = this.sessionStorageService.get<keyof QuoteSlots>(STORAGE_KEY_QT_ACTIVE_SLOT) ?? 1;
    const studentDetails = this.sessionStorageService.get<StudentDetails>(STORAGE_KEY_QT_STUDENT_DETAILS) ?? ({} as StudentDetails);
    const ageGroup = this.sessionStorageService.get<AgeGroup>(STORAGE_KEY_QT_AGE_GROUP);
    const residenceData = this.sessionStorageService.get<ResidenceData>(STORAGE_KEY_QT_RESIDENCE_DATA);
    const quoteSummaryCurrency = this.sessionStorageService.get<CurrencyCode>(STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY);
    return { quoteSlots, activeStep, activeSlot, studentDetails, ageGroup, residenceData, quoteSummaryCurrency };
  }

  private getStepId$() {
    return this.currentStepDefinition$.pipe(map((definition) => definition?.stepId));
  }

  private initQuoteSlots(quoteSlots) {
    this.quoteSlots = {
      1: new BehaviorSubject<QuoteOutput>(quoteSlots[1] ?? null),
      2: new BehaviorSubject<QuoteOutput>(quoteSlots[2] ?? null),
      3: new BehaviorSubject<QuoteOutput>(quoteSlots[3] ?? null),
    };
  }

  private initStudentDetails(studentDetails: StudentDetails) {
    this._studentDetails$.next(studentDetails);
  }

  private getActiveStep(): ROUTE_IDS {
    return this.sessionStorageService.get<ROUTE_IDS>(STORAGE_KEY_QT_ACTIVE_STEP_ID);
  }

  private getFullState(): Observable<QuoteToolFullState> {
    return combineLatest([
      this.getQuoteOutputs(),
      this.currentStepDefinition$,
      this.activeQuoteSlot$,
      this.studentDetails$,
      this.activeAgeGroup$,
      this.quoteSummaryCurrency$,
    ]).pipe(
      map(([quotes, currentStepDefinition, activeSlot, studentDetails, ageGroup, quoteSummaryCurrency]) => ({
        quotes,
        currentStepDefinition,
        activeSlot,
        studentDetails,
        ageGroup: ageGroup,
        quoteSummaryCurrency,
        residenceData: this.getResidenceData(),
      })),
    );
  }

  private changeEndpointConfigs(ageGroup: AgeGroup) {
    this.countryService.changeProductPackage(ageGroup === 'junior' ? ProductPackageEnum.YOUNG_LEARNER_CAMPS : null);
    this.languageService.changeProductPackage(ageGroup === 'junior' ? ProductPackageEnum.YOUNG_LEARNER_CAMPS : null);
  }

  public getResidenceData() {
    return this._residenceData$.getValue();
  }

  public setResidenceData(residenceData: ResidenceData) {
    if (!isDeepEqual(residenceData, this.getResidenceData())) {
      this._residenceData$.next(residenceData);
      this.sessionStorageService.set(STORAGE_KEY_QT_RESIDENCE_DATA, residenceData);
    }

    this.initResidenceCurrency(residenceData?.countryOfResidence);
  }

  public setCountryOfResidence(newCountryOfResidence: string) {
    const residenceData = this.getResidenceData();
    this.setResidenceData({
      ...residenceData,
      countryOfResidence: newCountryOfResidence,
    });
    this.initResidenceCurrency(newCountryOfResidence);
  }

  public getQuoteSummaryCurrency() {
    return this._quoteSummaryCurrency$.getValue();
  }

  public getResidenceCurrency() {
    return this.residenceCurrency;
  }

  public setQuoteSummaryCurrency(quoteSummaryCurrency: CurrencyCode) {
    if (!isDeepEqual(quoteSummaryCurrency, this.getQuoteSummaryCurrency())) {
      this._quoteSummaryCurrency$.next(quoteSummaryCurrency);
      this.sessionStorageService.set(STORAGE_KEY_QT_QUOTE_SUMMARY_CURRENCY, quoteSummaryCurrency);
    }
  }

  public getSchoolCodeAndName$(quoteInput: QuoteInput): Observable<CodeAndName> {
    if (this.isJuniors()) {
      return this.campService.getBySchoolCode(quoteInput.camp).pipe(map(({ schoolCode: code, name }) => ({ code, name })));
    } else {
      return this.schoolService.getByCode(quoteInput.school).pipe(map(({ code, name }) => ({ code, name })));
    }
  }

  public hasSchoolSpecialDietSupplement$(quoteInput: QuoteInput): Observable<boolean> {
    return this.schoolService.getByCode(quoteInput.school).pipe(map((school) => school?.hasSpecialDietSupplement));
  }

  public getCourseCodeAndName$(quoteInput: QuoteInput): Observable<CodeAndName> {
    if (this.isJuniors()) {
      const packageContext = {
        productPackage: quoteInput.productPackage,
        quoteDate: getDateStringFromDate(new Date()),
        residenceCountryCode: this.getResidenceData().countryOfResidence,
        startDate: quoteInput.startDate,
        endDate: quoteInput.endDate,
        units: quoteInput.tuitionWeeks,
      } as PackageContext;
      const { camp, languageCode, course } = quoteInput;
      return this.packageService.getByPackageContextCampAndLanguage(packageContext, camp, languageCode).pipe(
        map((packages) => {
          const { code, name } = packages.find((p) => p.code === course);
          return { code, name };
        }),
      );
    } else {
      const { startDate, endDate, tuitionWeeks, quoteDate, course, school } = quoteInput;
      return this.courseService
        .getAllWithPrice(this.getResidenceData()?.countryOfResidence, startDate, endDate, tuitionWeeks, school, this.isJuniors(), quoteDate)
        .pipe(
          map((courses) => {
            const { code, name } = courses.find((c) => c.code === course);
            return { code, name };
          }),
        );
    }
  }

  private getStudentDetailsWizardMode(): Partial<StudentDetails> {
    const studentDetails = this._studentDetails$.getValue();

    if (!this.isJuniors()) {
      studentDetails.guardianFirstName = null;
      studentDetails.guardianLastName = null;
      studentDetails.dateOfBirth = null;
    }

    return studentDetails;
  }

  public getStudentDetails(): Partial<StudentDetails> {
    return deepClone(this._studentDetails$.getValue());
  }

  private isValidInput(input: QuoteInput): boolean {
    return !!input?.languageCode && (!!input?.school || !!input?.camp) && !!input.destinationCurrency && !!input?.course;
  }

  private getInitialStep(desiredStep: ROUTE_IDS) {
    const activeStep = this.getActiveStep();

    switch (this.company) {
      case COMPANIES.ESL:
        return activeStep ?? desiredStep;
      default: {
        const mandatoryStep = !this.getResidenceData() ? ROUTE_IDS.RESIDENCE_COUNTRY : undefined;
        // for KIL & ALP, desired step is based on the QuoteTool Strategies
        return mandatoryStep ?? desiredStep ?? activeStep;
      }
    }
  }

  public getQuoteInfoForActiveQuote(): Observable<[QuoteOutput, CodeAndName, CodeAndName, CodeAndName]> {
    return this.getActiveQuote().pipe(
      switchMap((activeQuote: QuoteOutput) =>
        combineLatest([
          of(activeQuote),
          this.getSchoolCodeAndName$(activeQuote.input),
          this.getLanguageCodeAndName$(activeQuote.input),
          this.getCourseCodeAndName$(activeQuote.input),
        ]),
      ),
    );
  }

  public getActiveQuote(): Observable<QuoteOutput> {
    return this.activeQuoteSlot$.pipe(
      switchMap((activeSlot: keyof QuoteSlots) => this.getQuoteOutputs().pipe(map((quoteOutputs: SlottedObject<QuoteOutput>) => quoteOutputs[activeSlot]))),
    );
  }

  public getQuoteSlot(newSlot: string): keyof QuoteSlots {
    return parseInt(newSlot, 10) as keyof QuoteSlots;
  }

  private resetInactiveSlots() {
    Object.keys(this.quoteSlots)
      .filter((s) => +s !== +this.getActiveSlot())
      .forEach((slot) => {
        this.resetSlot(slot as unknown as keyof QuoteSlots);
      });
  }

  private moveActiveSlotToFirst() {
    if (Number(this.getActiveSlot()) === 1) {
      return;
    }
    this.quoteSlots[1].next(this.quoteSlots[this.getActiveSlot()].getValue());
    this.resetSlot(this.getActiveSlot());
    this.setActiveSlot(1);
  }

  private initResidenceCurrency(countryOfResidence: string) {
    const country = this.getCountryToUseForCurrencySelection(countryOfResidence);

    this.residenceCountryService
      .getByCode(country)
      .pipe(first())
      .subscribe((residenceCountry) => (this.residenceCurrency = residenceCountry?.currency ?? 'USD'));
  }

  private getCountryToUseForCurrencySelection(countryOfResidence: string) {
    if (!countryOfResidence) {
      // no country of residence -> default to market country
      return this.marketCountry;
    }

    const isUsingMarketCurrency = this.configuration.USE_MARKET_CURRENCY as boolean;
    const useResidenceCurrencyCountries = this.getUseResidenceCurrencyCountries();
    // useResidenceCurrency takes precedence over useMarketCurrency
    if (!useResidenceCurrencyCountries.includes(countryOfResidence) && isUsingMarketCurrency) {
      // we need to use market currency -> return market country
      return this.marketCountry;
    }

    // no changes needed
    return countryOfResidence;
  }

  private getUseResidenceCurrencyCountries(): string[] {
    const configValue = this.configuration?.USE_RESIDENCE_CURRENCY_COUNTRIES as string;
    if (configValue === undefined || configValue === null || configValue.trim() === '') {
      return [];
    }

    try {
      return JSON.parse(configValue) as string[];
    } catch (e) {
      return [];
    }
  }

  /**
   * Gets the quote prices for the given quote input
   * @param quoteInput The quote input to get the prices for
   */
  private getQuotePrices(quoteInput: QuoteInput) {
    return this.quoteService.get(quoteInput, this.getResidenceData()?.countryOfResidence);
  }
}

import { inject, Injectable, OnDestroy } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { defaultQuoteToolState, OptOutProduct, QuoteToolSlot, QuoteToolState } from '../state/quote-tool.state';
import { Observable, Subscription, take } from 'rxjs';
import { SessionStorageService, STORAGE_KEY_QT_STATE } from '@klg/shared/storage';
import { CountryRegion, ResidenceCountry } from '@klg/shared/data-access/destination';
import { Country, CourseType } from '@klg/shared/data-access/types';
import { CourseSelectionDto, QuoteCamp, QuoteOnlineSchool, QuoteSchool, SchoolPackageDto } from '@klg/quote-tool/shared/types';
import { PremiumActivity } from '@klg/shared/data-access/school';
import { SchoolSupplement, Transfer } from '@klg/shared/data-access/products';

@Injectable({ providedIn: 'root' })
export class QuoteToolStore extends ComponentStore<QuoteToolState> implements OnDestroy {
  private readonly storageKey = STORAGE_KEY_QT_STATE;

  private subscriptions: Subscription = new Subscription();

  private readonly sessionStorageService = inject(SessionStorageService);

  constructor() {
    super(defaultQuoteToolState);
    this.loadInitialState();
  }

  // --------------------------Selectors--------------------------

  // Selector for getting the slots
  readonly quoteToolSlots$ = this.select(({ slots }) => slots);

  // Selector for getting the student details
  readonly studentDetails$ = this.select(({ studentDetails }) => studentDetails);

  // Selector for getting the selected residence data (country and region)
  readonly residenceData$ = this.select(({ selectedCountryOfResidence, selectedRegionOfResidence }) => ({
    selectedCountryOfResidence,
    selectedRegionOfResidence,
  }));

  // Selector for getting the list of countries of residence
  readonly countriesOfResidence$ = this.select(({ countriesOfResidence }) => countriesOfResidence);

  // Selector for getting the list of regions of residence
  readonly regionsOfResidence$ = this.select(({ regionsOfResidence }) => regionsOfResidence);

  // Selector for getting the list of destination countries
  readonly destinationCountries$ = this.select(({ destinationCountries }) => destinationCountries);

  // Selector for getting the list of destination countries for data layers
  readonly dataLayerDestinationCountries$ = this.select(({ dataLayerDestinationCountries }) => dataLayerDestinationCountries);

  // Selector for getting the list of camps
  readonly camps$ = this.select(({ camps }) => camps);

  // Selector for getting the list of packages
  readonly packages$ = this.select(({ packages }) => packages);

  // Selector for getting the list of schools
  readonly schools$ = this.select(({ schools }) => schools);

  // Selector for getting the list of courses
  readonly courses$ = this.select(({ courses }) => courses);

  // Selector for getting the list of schools
  readonly courseTypes$ = this.select(({ courseTypes }) => courseTypes);

  // Selector for getting the list of premium activities
  readonly premiumActivities$ = this.select(({ premiumActivities }) => premiumActivities);

  // Selector for getting the list of transfers
  readonly transfers$ = this.select(({ transfers }) => transfers);

  // Selector for getting the list of school supplements
  readonly schoolSupplements$ = this.select(({ schoolSupplements }) => schoolSupplements);

  // Selector for getting the online school code
  readonly onlineSchoolCode$ = this.select(({ onlineSchoolCode }) => onlineSchoolCode);

  // Selector for getting the opt-out product
  readonly optOutProduct$ = this.select(({ optOutProduct }) => optOutProduct);

  // Selector for getting the opt-out product info
  readonly optOutProductInfo$ = this.select(({ optOutProduct }) => optOutProduct?.optOutProductInfo);

  // --------------------------Effects--------------------------

  // Effect for updating a slot
  readonly updateSlot$ = this.effect((slot$: Observable<Partial<QuoteToolSlot>>) => {
    return slot$.pipe(
      tapResponse(
        (slot: Partial<QuoteToolSlot>) => {
          this.updateSlot(slot);
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for resetting the slots of the state:
  // Reset slots of the state with the information of the slot whose key is passed by parameter but
  // set it into a new slot with key = 1
  readonly resetSlots$ = this.effect((key$: Observable<number>) => {
    return key$.pipe(
      tapResponse(
        (key: number) => {
          // Get the state
          const state = this.get();

          // Find slot in state
          const slot = state.slots.find((s) => s.key === Number(key));
          if (slot) {
            // Update slot 1 with the state of the slot passed as parameter
            this.updateSlots([
              {
                ...slot,
                key: 1,
              },
            ]);
          } else {
            this.updateSlots(defaultQuoteToolState.slots);
          }
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for removing a slot
  readonly removeSlot$ = this.effect((key$: Observable<number>) => {
    return key$.pipe(
      tapResponse(
        (key: number) => {
          this.removeSlot(key);
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for updating the state
  readonly updateState$ = this.effect((state$: Observable<Partial<QuoteToolState>>) => {
    return state$.pipe(
      tapResponse(
        (state: Partial<QuoteToolState>) => {
          this.updateState(state);
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for updating the residence data in state
  readonly updateResidenceData$ = this.effect((state$: Observable<Partial<QuoteToolState>>) => {
    return state$.pipe(
      tapResponse(
        ({ selectedCountryOfResidence, selectedRegionOfResidence }: Partial<QuoteToolState>) => {
          this.updateResidenceData({ selectedCountryOfResidence, selectedRegionOfResidence });
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for updating the online school code in state
  readonly updateOnlineSchoolCode$ = this.effect((quoteOnlineSchool$: Observable<QuoteOnlineSchool>) => {
    return quoteOnlineSchool$.pipe(
      tapResponse(
        (quoteOnlineSchool: QuoteOnlineSchool) => {
          this.updateOnlineSchoolCode(quoteOnlineSchool);
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // Effect for updating the opt-out product in state
  readonly updateOptOutProduct$ = this.effect((optOutProduct$: Observable<OptOutProduct>) => {
    return optOutProduct$.pipe(
      tapResponse(
        (optOutProduct: OptOutProduct) => {
          this.updateOptOutProduct(optOutProduct);
          this.saveState();
        },
        (error) => {
          throw error;
        },
      ),
    );
  });

  // --------------------------Updaters--------------------------

  // Updater to update the slots of the state with a list of slots
  readonly updateSlots = this.updater((state, slots: QuoteToolSlot[]) => ({
    ...state,
    slots,
  }));

  // Updater to update the state with a slot
  readonly updateSlot = this.updater((state, slot: Partial<QuoteToolSlot>) => {
    // Get a copy of slot and ensure key is a number always
    const newSlot = {
      ...slot,
      key: Number(slot.key),
    };

    // Get a copy of the current state and add/update the slot
    const newState = { ...state };

    // Find slot in state
    const slotIndex = this.findSlotIndex(newState.slots, newSlot.key);
    if (slotIndex > -1) {
      // Update slot
      const originalSlot = newState.slots[slotIndex];
      newState.slots[slotIndex] = { ...originalSlot, ...newSlot };
    } else {
      // Add slot
      newState.slots.push({
        ...newSlot,
      });
    }

    return newState;
  });

  // Updater to remove a slot from the state
  readonly removeSlot = this.updater((state, key: number) => {
    const newState = { ...state };
    const slotIndex = this.findSlotIndex(newState.slots, key);
    if (slotIndex > -1) {
      newState.slots.splice(slotIndex, 1);
    }
    return newState;
  });

  // Updater to update residence data
  readonly updateResidenceData = this.updater((state, { selectedCountryOfResidence, selectedRegionOfResidence }: Partial<QuoteToolState>) => ({
    ...state,
    selectedCountryOfResidence,
    selectedRegionOfResidence,
  }));

  // Updater to update the list of residence countries
  readonly updateCountriesOfResidence = this.updater((state, countriesOfResidence: ResidenceCountry[]) => ({
    ...state,
    countriesOfResidence,
  }));

  // Updater to update the list of residence regions
  readonly updateRegionsOfResidence = this.updater((state, regionsOfResidence: CountryRegion[]) => ({
    ...state,
    regionsOfResidence,
  }));

  // Updater to update the list of destination countries
  readonly updateDestinationCountries = this.updater((state, destinationCountries: Country[]) => ({
    ...state,
    destinationCountries,
  }));

  // Updater to update the online school code
  readonly updateOnlineSchoolCode = this.updater((state, onlineSchoolCode: QuoteOnlineSchool) => ({
    ...state,
    onlineSchoolCode,
  }));

  // Updater to update the opt-out product
  readonly updateOptOutProduct = this.updater((state, optOutProduct: OptOutProduct) => ({
    ...state,
    optOutProduct,
  }));

  // Updater to update any field of the state
  readonly updateState = this.updater((state, partialState: Partial<QuoteToolState>) => ({
    ...state,
    ...partialState,
  }));

  readonly addDataLayerDestinationCountries = (dataLayerDestinationCountries: Country[]) => {
    this.patchState((state) => ({
      dataLayerDestinationCountries: [...(state.dataLayerDestinationCountries || []), ...dataLayerDestinationCountries],
    }));
  };

  readonly addCamps = (camps: QuoteCamp[]) => {
    this.patchState((state) => ({
      camps: [...(state.camps || []), ...camps],
    }));
  };

  readonly addPackages = (packages: SchoolPackageDto[]) => {
    this.patchState((state) => ({
      packages: [...(state.packages || []), ...packages],
    }));
  };

  readonly addSchools = (schools: QuoteSchool[]) => {
    this.patchState((state) => ({
      schools: [...(state.schools || []), ...schools],
    }));
  };

  readonly addCourses = (courses: CourseSelectionDto[]) => {
    this.patchState((state) => ({
      courses: [...(state.courses || []), ...courses],
    }));
  };

  readonly addCourseTypes = (courseTypes: CourseType[]) => {
    this.patchState((state) => ({
      courseTypes: [...(state.courseTypes || []), ...courseTypes],
    }));
  };

  readonly addPremiumActivities = (premiumActivities: PremiumActivity[]) => {
    this.patchState((state) => ({
      premiumActivities: [...(state.premiumActivities || []), ...premiumActivities],
    }));
  };

  readonly addTransfers = (transfers: Transfer[]) => {
    this.patchState((state) => ({
      transfers: [...(state.transfers || []), ...transfers],
    }));
  };

  readonly addSchoolSupplements = (schoolSupplements: SchoolSupplement[]) => {
    this.patchState((state) => ({
      schoolSupplements: [...(state.schoolSupplements || []), ...schoolSupplements],
    }));
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Find the index of a slot in the state making sure the key is a number
  private findSlotIndex(slots: QuoteToolSlot[], key: number | string) {
    return slots.findIndex((s) => s.key === Number(key));
  }

  // Load the initial state from local storage
  private loadInitialState() {
    const stateFromSessionStorage = <QuoteToolState>this.sessionStorageService.get(this.storageKey);
    this.updateState(stateFromSessionStorage ? stateFromSessionStorage : defaultQuoteToolState);
  }

  // Save the current state to local storage
  private saveState() {
    const { slots, selectedCountryOfResidence, selectedRegionOfResidence, onlineSchoolCode, optOutProduct } = this.get();
    this.sessionStorageService.set(this.storageKey, {
      slots,
      selectedCountryOfResidence,
      selectedRegionOfResidence,
      onlineSchoolCode,
      optOutProduct,
    });
  }
}

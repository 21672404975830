import { inject, Injectable } from '@angular/core';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { GoogleAnalyticsDataLayerService, GoogleAnalyticsEvent, GoogleAnalyticsQuoteDetails } from '@klg/shared/google-analytics';
import {
  AgeGroups,
  CourseSelectionDto,
  PremiumActivitiesSelection,
  QuoteCamp,
  QuoteDiscounts,
  QuoteSchool,
  SchoolPackageDto,
  StudentDetails,
} from '@klg/quote-tool/shared/types';
import { Country, CourseType } from '@klg/shared/data-access/types';
import { GoogleAnalyticsSteps } from './google-analytics.steps.enum';
import { QuoteToolSlot, QuoteToolStore } from '@klg/quote-tool/shared/store';
import { StepService } from '@klg/quote-tool/shared/services';
import { convertToTitleCase } from '@klg/shared/utils';
import { PremiumActivity } from '@klg/shared/data-access/school';
import { SchoolSupplement, Transfer, TransferDirectionEnum } from '@klg/shared/data-access/products';
import { GA_EVENT_VARIABLE_MAPPINGS } from './google-analytics-event-mapping.constant';

const WITH_VALUE = '1';
const NO_VALUE = '0';

@Injectable({
  providedIn: 'root',
})
export class QuoteToolGoogleAnalyticsDataLayerService implements GoogleAnalyticsDataLayerService {
  private readonly stepService = inject(StepService);
  private readonly quoteToolStore = inject(QuoteToolStore);
  private readonly variableMappings = GA_EVENT_VARIABLE_MAPPINGS;

  fillDataLayer(): Observable<Partial<GoogleAnalyticsEvent>> {
    const step = GoogleAnalyticsSteps[this.stepService.getCurrentActiveStep()];

    // If there is no residence data, return early with only step information
    if (!this.stepService.getResidenceData()) {
      return of({ step });
    }

    // TODO fill the rest of fields that have to be sent to data layer. Add the
    // according ones per each step/Jira task
    return combineLatest([this.fillResidenceCountryData(), this.quoteToolStore.quoteToolSlots$, this.fillStudentDetails()]).pipe(
      mergeMap(([residenceCountryData, quoteToolSlots, studentDetails]) => {
        // Map over the quoteToolSlots and create an observable for each
        const slotObservables = quoteToolSlots.map((quoteToolSlot) =>
          combineLatest([
            this.fillLanguageAndProgramTypeData(quoteToolSlot),
            this.fillSchoolSelectionData(quoteToolSlot),
            this.fillExtrasSelectionData(step, quoteToolSlot),
          ]).pipe(
            map(([languageAndProgramTypeData, schoolSelectionData, extrasSelectionData]) => ({
              ...languageAndProgramTypeData,
              ...schoolSelectionData,
              ...extrasSelectionData,
            })),
          ),
        );

        // Combine results from all slot observables
        return combineLatest(slotObservables).pipe(
          map((quotes) => ({
            step,
            ...residenceCountryData,
            ...studentDetails,
            quotes,
          })),
        );
      }),
    );
  }

  // Deeply merges two Google Analytics event objects.
  deepMerge(target: Partial<GoogleAnalyticsEvent>, source: Partial<GoogleAnalyticsEvent>): Partial<GoogleAnalyticsEvent> {
    // Create a shallow copy of both target and source.
    const merged = { ...target, ...source };

    // If both target and source have 'quotes' arrays, merge them deeply.
    if (Array.isArray(target?.quotes) && Array.isArray(source?.quotes)) {
      merged.quotes = this.mergeQuotes(target.quotes, source.quotes);
    }

    // Return the merged object.
    return merged;
  }

  //Removes unnecessary session data from the stored data layer.
  removeUnnecessarySessionData(storedDataLayer: Partial<GoogleAnalyticsEvent>, newDataLayer: Partial<GoogleAnalyticsEvent>): Partial<GoogleAnalyticsEvent> {
    const updatedStoredDataLayer = { ...storedDataLayer };
    // Check if both data layers contain 'quotes' arrays.
    if (Array.isArray(storedDataLayer?.quotes) && Array.isArray(newDataLayer?.quotes)) {
      // Create a map from the new quotes for quick lookup by 'quote_slot'.
      const newQuotesMap = new Map(newDataLayer.quotes.map((quote) => [quote.quote_slot, quote]));

      // Filter and update quotes that have corresponding quote slots in the new data layer.
      updatedStoredDataLayer.quotes = storedDataLayer.quotes
        .filter((storedQuote) => newQuotesMap.has(storedQuote.quote_slot))
        .map((storedQuote) => {
          const correspondingNewQuote = newQuotesMap.get(storedQuote.quote_slot);
          if (correspondingNewQuote) {
            // Use the modified quote returned by removeUnnecessaryVariables
            return this.removeUnnecessaryVariables(storedQuote, correspondingNewQuote);
          }
          return storedQuote;
        });
    }

    return updatedStoredDataLayer;
  }

  private removeUnnecessaryVariables(storedQuote: GoogleAnalyticsQuoteDetails, newQuote: GoogleAnalyticsQuoteDetails): GoogleAnalyticsQuoteDetails {
    // Create a copy of storedQuote
    const modifiedQuote = { ...storedQuote };

    // Iterate over variable mappings and remove unnecessary variables from the copy
    Object.entries(this.variableMappings).forEach(([key, variables]) => {
      if (newQuote[key as keyof GoogleAnalyticsQuoteDetails] === NO_VALUE) {
        variables.forEach((variable) => {
          delete modifiedQuote[variable as keyof GoogleAnalyticsQuoteDetails];
        });
      }
    });

    return modifiedQuote;
  }

  private mergeQuotes(targetQuotes: GoogleAnalyticsQuoteDetails[], sourceQuotes: GoogleAnalyticsQuoteDetails[]): GoogleAnalyticsQuoteDetails[] {
    const mergedQuotes = [...targetQuotes];

    sourceQuotes.forEach((sourceQuote: GoogleAnalyticsQuoteDetails) => {
      const targetQuoteIndex = mergedQuotes.findIndex((targetQuote) => targetQuote.quote_slot === sourceQuote.quote_slot);

      if (targetQuoteIndex !== -1) {
        mergedQuotes[targetQuoteIndex] = { ...mergedQuotes[targetQuoteIndex], ...sourceQuote };
      } else {
        mergedQuotes.push({ ...sourceQuote });
      }
    });

    return mergedQuotes;
  }

  private fillResidenceCountryData(): Observable<Partial<GoogleAnalyticsEvent>> {
    return combineLatest([this.quoteToolStore.countriesOfResidence$, this.quoteToolStore.regionsOfResidence$, this.quoteToolStore.residenceData$]).pipe(
      map(([countriesOfResidence, regionsOfResidence, residenceData]) => {
        const countryOfResidence = countriesOfResidence?.filter(({ code }) => code === residenceData?.selectedCountryOfResidence)[0]?.name;
        const cityOfResidence = regionsOfResidence?.filter(({ code }) => code === residenceData?.selectedRegionOfResidence)[0]?.name;
        return {
          quote_country_residence: countryOfResidence,
          quote_city_residence: cityOfResidence,
        };
      }),
    );
  }

  private fillLanguageAndProgramTypeData(quoteToolSlot: QuoteToolSlot): Observable<Partial<GoogleAnalyticsQuoteDetails>> {
    return of({
      quote_slot: quoteToolSlot?.key,
      quote_language_interest: quoteToolSlot?.language,
      quote_program_type: quoteToolSlot?.programType,
    });
  }

  private fillSchoolSelectionData(quoteToolSlot: QuoteToolSlot): Observable<Partial<GoogleAnalyticsQuoteDetails>> {
    const { camps$, packages$, schools$, courses$, courseTypes$, dataLayerDestinationCountries$ } = this.quoteToolStore;
    return combineLatest([
      of(quoteToolSlot),
      dataLayerDestinationCountries$,
      iif(() => quoteToolSlot?.programType === AgeGroups.Junior, combineLatest([camps$, packages$]), combineLatest([schools$, courses$, courseTypes$])),
    ]).pipe(
      map(
        ([quote, destinationCountries, [schools, courses, courseTypes]]: [
          QuoteToolSlot,
          Country[],
          [QuoteCamp[] | QuoteSchool[], SchoolPackageDto[] | CourseSelectionDto[], CourseType[]],
        ]) => {
          // If there is no quote, there is no data to fill
          if (!quote) {
            return {};
          }

          const { country, school, course, duration, startDate, endDate, courseType, programType } = quote;

          // Get common data for all the cases
          const commonData = {
            quote_country_interest: destinationCountries?.filter(({ code }) => code === country)[0]?.name,
            quote_duration_interest: duration?.toString(),
            quote_start_date: this.formatDate(startDate),
            quote_end_date: this.formatDate(endDate),
          };

          // Fill the specific data for juniors and return
          if (programType === AgeGroups.Junior) {
            return {
              ...commonData,
              quote_school_interest: this.getCampName(schools, school),
              quote_course_detail: this.getPackageName(courses, course),
            };
          }

          // Fill the specific data for the rest of scenarios and return
          return {
            ...commonData,
            quote_school_interest: this.getSchoolName(schools, school),
            quote_course_type: courseTypes?.filter(({ code }) => code === courseType)[0]?.name,
            quote_course_detail: this.getCourseName(courses, course),
            quote_offer_available: this.isOfferAvailable(courses, course),
          };
        },
      ),
    );
  }

  private fillExtrasSelectionData(step: number, quoteToolSlot: QuoteToolSlot): Observable<Partial<GoogleAnalyticsQuoteDetails>> {
    const activeSlot = this.stepService.getActiveSlot();
    if (step < GoogleAnalyticsSteps['quote'] && quoteToolSlot?.key === Number(activeSlot)) {
      return of({});
    }

    const { premiumActivities$, transfers$, schoolSupplements$ } = this.quoteToolStore;

    return combineLatest([of(quoteToolSlot), premiumActivities$, transfers$, schoolSupplements$]).pipe(
      map(([storedQuote, premiumActivities, transfers, schoolSupplements]: [QuoteToolSlot, PremiumActivity[], Transfer[], SchoolSupplement[]]) => {
        const {
          accommodation,
          insurance,
          transfer,
          programType,
          virtualInternship,
          destinationCurrency,
          marketCurrency,
          grandTotal,
          discountTotal,
          discounts,
          productTotal,
        } = storedQuote || {};
        const accommodationData = accommodation?.accommodation;
        const transferFares = transfer?.fares?.filter((fares) => fares?.row) || [];
        const insuranceWeeks = insurance?.weeks;

        const commonData = {
          quote_insurance_type: insuranceWeeks ? WITH_VALUE : NO_VALUE,
          quote_transfer_type: transferFares.length ? WITH_VALUE : NO_VALUE,
          quote_total_amount: Math.round(grandTotal?.filter(({ currency }) => currency === destinationCurrency)[0]?.value)?.toString(),
          quote_promo_code_name: this.getPromoCodeNames(discounts),
          quote_promo_applied: discounts?.length ? WITH_VALUE : NO_VALUE,
          quote_price_original: Math.round(productTotal?.filter(({ currency }) => currency === marketCurrency)[0]?.value)?.toString(),
          quote_price_discounted: Math.round(grandTotal?.filter(({ currency }) => currency === marketCurrency)[0]?.value)?.toString(),
          quote_discount_amount: Math.floor(discountTotal?.filter(({ currency }) => currency === marketCurrency)[0]?.value)?.toString(),
          quote_destination_currency: destinationCurrency !== marketCurrency ? destinationCurrency : NO_VALUE,
          quote_local_currency: marketCurrency,
        };

        if (programType === AgeGroups.Junior) {
          const juniorExtrasSelectionData = this.getJuniorExtrasSelectionData(storedQuote, premiumActivities, transfers, schoolSupplements);
          const quote_extras = [commonData.quote_insurance_type, commonData.quote_transfer_type, ...Object.values(juniorExtrasSelectionData)].includes(
            WITH_VALUE,
          )
            ? WITH_VALUE
            : NO_VALUE;
          return {
            ...commonData,
            ...juniorExtrasSelectionData,
            quote_extras,
          };
        }

        return {
          ...commonData,
          quote_insurance_weeks: insuranceWeeks?.toString(),
          quote_transfer_type_name: [...new Set(transferFares.map((fare) => fare.transferStation))].filter(Boolean).join(', ') || null,
          quote_accommodation_type: accommodationData ? WITH_VALUE : NO_VALUE,
          quote_accommodation_name: accommodationData?.name,
          quote_accommodation_bedroom: convertToTitleCase(accommodationData?.roomType),
          quote_accommodation_bathroom: convertToTitleCase(accommodationData?.bathroomType),
          quote_accommodation_meal: convertToTitleCase(accommodationData?.mealOptions),
          quote_virtual_internship_type: virtualInternship ? WITH_VALUE : NO_VALUE,
          quote_virtual_internship_weeks: virtualInternship?.weeks?.toString(),
        };
      }),
    );
  }

  private fillStudentDetails(): Observable<Partial<GoogleAnalyticsEvent>> {
    return this.quoteToolStore.studentDetails$.pipe(
      map((studentDetails: StudentDetails) => {
        if (!studentDetails) {
          return {};
        }
        const marketingOptIn = studentDetails.consentMarketing ? NO_VALUE : WITH_VALUE;

        if (studentDetails.title) {
          return {
            quote_enrol_title: WITH_VALUE,
            quote_enrol_name: studentDetails.firstName ? WITH_VALUE : NO_VALUE,
            quote_enrol_lastname: studentDetails.lastName ? WITH_VALUE : NO_VALUE,
            quote_enrol_birth: studentDetails.dateOfBirth ? WITH_VALUE : NO_VALUE,
            quote_enrol_mothertongue: studentDetails.motherTongue ? WITH_VALUE : NO_VALUE,
            quote_enrol_nationality: studentDetails.nationality ? WITH_VALUE : NO_VALUE,
            quote_enrol_email: studentDetails.email ? WITH_VALUE : NO_VALUE,
            quote_enrol_phone: studentDetails.phoneNumber ? WITH_VALUE : NO_VALUE,
            quote_enrol_address: studentDetails.address ? WITH_VALUE : NO_VALUE,
            quote_enrol_specialneeds: studentDetails.specialRequests ? WITH_VALUE : NO_VALUE,
            quote_enrol_over18: studentDetails.isMinor ? NO_VALUE : WITH_VALUE,
            quote_enrol_marketing_opt_in: marketingOptIn,
          };
        }

        return { quote_email_marketing_opt_in: marketingOptIn };
      }),
    );
  }

  private getSchoolName(schools: QuoteSchool[], schoolCode: string) {
    return schools?.filter(({ code }) => code === schoolCode)[0]?.name;
  }

  private getCampName(camps: QuoteCamp[], campCode: string) {
    return camps?.filter(({ code }) => code === campCode)[0]?.name;
  }

  private getCourseName(courses: CourseSelectionDto[], courseCode: string) {
    return courses?.filter(({ code }) => code === courseCode)[0]?.name;
  }

  private getPackageName(packages: SchoolPackageDto[], packageCode: string) {
    return packages?.filter(({ code }) => code === packageCode)[0]?.name;
  }

  private isOfferAvailable(courses: CourseSelectionDto[], courseCode: string): string {
    const course = courses?.find(({ code }) => code === courseCode);
    if (!course) {
      return;
    }
    const discountValue = course.discount?.value;
    return discountValue ? WITH_VALUE : NO_VALUE;
  }

  private getJuniorExtrasSelectionData(
    storedQuote: QuoteToolSlot,
    allPremiumActivities: PremiumActivity[],
    transfers: Transfer[],
    schoolSupplements: SchoolSupplement[],
  ) {
    const { transfer, premiumActivities, premiumResidence, privateLessons } = storedQuote || {};

    const selectedPremiumActivities = premiumActivities?.filter((premiumActivity) => premiumActivity?.selectedWeeks?.length) || [];
    const transferFares = transfer?.fares || [];
    const unaccompaniedMinors = transfer?.unaccompaniedMinors || [];
    const premiumResidenceWeeks = premiumResidence?.weeks;
    const privateLessonsWeeks = privateLessons?.weeks || [];
    const unaccompaniedMinorDeparture = unaccompaniedMinors.find(
      (minor) => this.getUnaccompaniedMinorDirection(minor, schoolSupplements) === TransferDirectionEnum.DEPARTURE,
    );
    const unaccompaniedMinorArrival = unaccompaniedMinors.find(
      (minor) => this.getUnaccompaniedMinorDirection(minor, schoolSupplements) === TransferDirectionEnum.ARRIVAL,
    );
    return {
      quote_unaccompanied_departure: unaccompaniedMinorDeparture ? WITH_VALUE : NO_VALUE,
      quote_unaccompanied_arrival: unaccompaniedMinorArrival ? WITH_VALUE : NO_VALUE,
      quote_arrival_pickup: transfers?.filter((transfer) => transferFares[0]?.row === transfer.row)[0]?.transferStation,
      quote_departure_dropoff: transfers?.filter((transfer) => transferFares[1]?.row === transfer.row)[0]?.transferStation,
      quote_premium_activities: selectedPremiumActivities.length ? WITH_VALUE : NO_VALUE,
      quote_premium_activities_type: this.getPremiumActivitiesNames(allPremiumActivities, selectedPremiumActivities),
      quote_premium_activities_weeks: this.getPremiumActivitiesWeeks(selectedPremiumActivities),
      quote_private_lessons: privateLessonsWeeks.length ? WITH_VALUE : NO_VALUE,
      quote_private_lessons_weeks: privateLessonsWeeks.length ? privateLessonsWeeks.join(', ') : null,
      quote_premium_residence: premiumResidenceWeeks > 0 ? WITH_VALUE : NO_VALUE,
      quote_premium_residence_weeks: premiumResidenceWeeks > 0 ? premiumResidenceWeeks.toString() : null,
    };
  }

  private getUnaccompaniedMinorDirection(minor: SchoolSupplement, supplements: SchoolSupplement[]) {
    return supplements?.find((supplement) => supplement.row === minor?.row)?.transferDirection;
  }

  private getPremiumActivitiesNames(allPremiumActivities: PremiumActivity[], selectedPremiumActivities: PremiumActivitiesSelection[]) {
    if (!selectedPremiumActivities.length) {
      return;
    }
    return selectedPremiumActivities
      .map((selectedActivity) => allPremiumActivities.find((activity) => activity.code === selectedActivity.activityCode)?.name)
      .join(', ');
  }

  private getPremiumActivitiesWeeks(selectedPremiumActivities: PremiumActivitiesSelection[]) {
    if (!selectedPremiumActivities.length) {
      return;
    }
    return selectedPremiumActivities.map((premiumActivity) => premiumActivity.selectedWeeks).join(', ');
  }

  private getPromoCodeNames(discounts: QuoteDiscounts[]) {
    if (!discounts?.length) {
      return;
    }
    return discounts.map((discount) => discount.name).join(', ');
  }

  /**
   * Formats date in dd/mm/yyyy
   * @param date the date to format
   */
  private formatDate(date: string) {
    if (!date) {
      return undefined;
    }
    const matches = date?.match(/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/);
    return `${matches[3]}/${matches[2]}/${matches[1]}`;
  }
}

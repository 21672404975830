import { Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { deepClone, getDateFromString, getDateStringFromDate, getToday } from '@klg/shared/utils';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Calendar, CalendarModule } from 'primeng/calendar';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { DateFormat, DateFormatService } from '@klg/shared/i18n';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormFieldComponent } from '@klg/shared/ui/form-field';
import { DropdownModule } from 'primeng/dropdown';
import {
  DatePickerFieldDate,
  DatePickerFieldMonthAndYear,
  DropdownMonth,
  SCROLL_HEIGHT_COMMON_DEVICES,
  SCROLL_HEIGHT_SMALL_DEVICES,
} from './date-picker.types';
import { MAX_NUMBER_OF_YEARS } from './date-picker.constants';
import { UiModule } from '@klg/ui/components';
import { ButtonComponent } from '@klg/shared/ui/button';
import { ButtonModule } from 'primeng/button';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { GoogleAnalyticsClickEventDirective } from '@klg/shared/google-analytics';

@Component({
  standalone: true,
  selector: 'kng-date-picker-field',
  templateUrl: './date-picker-field.component.html',
  styleUrls: ['./date-picker-field.component.scss'],
  imports: [
    CalendarModule,
    FormsModule,
    NgClass,
    FormFieldComponent,
    DropdownModule,
    UiModule,
    ButtonComponent,
    ButtonModule,
    AsyncPipe,
    GoogleAnalyticsClickEventDirective,
  ],
})
export class DatePickerFieldComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('calendar') calendar: Calendar;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required = false;
  @Input() isValid: boolean;
  @Input() dateValue: string;
  @Input() dateObject: Date;
  @Input() defaultDate = getToday();
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabledDates: Date[];
  @Input() disabledDays: number[];
  @Input() showCalendarIcon: boolean;
  @Input() yearRange: string;
  @Input() googleAnalyticsClickEvent: string | undefined;
  @Output() dateChanged = new EventEmitter<DatePickerFieldDate>();
  @Output() monthChanged = new EventEmitter<DatePickerFieldMonthAndYear>();
  @Output() datePickerFocused = new EventEmitter<void>();

  dateFormat = 'd M yy';
  today = getToday();
  pickedDate: DatePickerFieldDate = {} as DatePickerFieldDate;

  monthScrollHeight = SCROLL_HEIGHT_COMMON_DEVICES;

  // Observable with selected date (today by default)
  selectedDate$ = new BehaviorSubject(getToday());

  years: number[] = [];
  months: DropdownMonth[] = [];
  selectedYear: number | undefined;
  selectedMonth: DropdownMonth | undefined;

  nextMonthDisabled = false;
  previousMonthDisabled = false;

  private subscription: Subscription = new Subscription();

  // Observer to check if the screen is short (less than 600px of height)
  private readonly shortScreen$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(window.matchMedia(`(max-height: ${SCROLL_HEIGHT_COMMON_DEVICES})`).matches),
    map(() => {
      return window.matchMedia(`(max-height: ${SCROLL_HEIGHT_COMMON_DEVICES})`).matches;
    }),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private readonly dateFormatService = inject(DateFormatService);

  ngOnInit(): void {
    this.dateFormat = this.dateFormatService.getDateFormatSettings()[DateFormat.SHORT_MONTH_NAME_PRIME_NG];
    this.prepareYears();
    this.prepareMonths();

    this.subscription.add(
      this.shortScreen$.subscribe((shortScreen) => {
        // If screen is short, reduce the size of the month dropdown scroll height
        if (shortScreen) {
          this.monthScrollHeight = SCROLL_HEIGHT_SMALL_DEVICES;
        } else {
          this.monthScrollHeight = SCROLL_HEIGHT_COMMON_DEVICES;
        }
      }),
    );

    this.subscription.add(
      this.selectedDate$.subscribe((date) => {
        // Get selected date, by default this will be today and set selectedMonth and selectedYear
        const selectedDate = date || this.today;
        this.selectedMonth = this.months.find((month) => month.month === selectedDate.getMonth());
        this.selectedYear = selectedDate.getFullYear();
      }),
    );
  }

  ngOnChanges({ dateValue, dateObject, defaultDate }: SimpleChanges): void {
    if (dateValue) {
      this.pickedDate = {
        dateString: dateValue.currentValue,
        dateObject: getDateFromString(dateValue.currentValue),
      };
    }

    if (dateObject) {
      this.pickedDate = {
        dateString: getDateStringFromDate(dateObject.currentValue),
        dateObject: deepClone(dateObject.currentValue),
      };

      this.selectedDate$.next(this.pickedDate.dateObject);
    }

    if (defaultDate) {
      this.selectedDate$.next(defaultDate?.currentValue || this.today);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Prepare the calendar before being open.
   * This means:
   * - to add to the calendar the data-title property with the placeholder or label
   * - to set the selected year and month
   * - to emit the dateChanged event with the picked date to have all disabled dates properly calculated with
   * the change of selected year and month
   */
  prepareCalendar() {
    const calendarElement = this.calendar.el.nativeElement;
    calendarElement.querySelector('.p-datepicker').dataset.title = this.placeholder || this.label;
    if (this.pickedDate.dateObject) {
      this.selectedYear = this.pickedDate.dateObject.getFullYear();
      this.selectedMonth = this.months.find((month) => month.month === this.pickedDate.dateObject.getMonth());
      this.dateChanged.emit(this.pickedDate);
    }
  }

  checkIsValid() {
    return this.isValid ?? (this.pickedDate?.dateObject ? true : null);
  }

  pickDate(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setUTCMilliseconds(0);

    this.pickedDate = {
      dateString: getDateStringFromDate(date),
      dateObject: date,
    };
    this.dateChanged.emit(this.pickedDate);
  }

  /**
   * Updates the selected date with selected year and month.
   * This means that monthChanged event is emitted with the selected month and year and
   * the navigation buttons state is calculated to know if they are disabled or not.
   */
  updateSelectedDate() {
    this.selectedDate$.next(new Date(this.selectedYear, this.selectedMonth.month, 1));
    // Emits the event with selectedMonth + 1 because the month is 0 based
    this.monthChanged.emit({ month: this.selectedMonth.month + 1, year: this.selectedYear });

    this.calculateNavigationButtonsState();
  }

  navigateToNextMonth() {
    const currentMonthIndex = this.months.findIndex(({ month }) => month === this.selectedMonth.month);
    if (currentMonthIndex === 11) {
      this.selectedYear++;
      this.selectedMonth = this.months[0];
    } else {
      this.selectedMonth = this.months[currentMonthIndex + 1];
    }

    this.updateSelectedDate();
  }

  navigateToPreviousMonth() {
    const currentMonthIndex = this.months.findIndex(({ month }) => month === this.selectedMonth.month);
    if (currentMonthIndex === 0) {
      this.selectedYear--;
      this.selectedMonth = this.months[11];
    } else {
      this.selectedMonth = this.months[currentMonthIndex - 1];
    }

    this.updateSelectedDate();
  }

  openCalendar() {
    // Defer execution to ensure DOM changes are applied.
    setTimeout(() => {
      const inputElement: HTMLInputElement = this.calendar?.inputfieldViewChild?.nativeElement;
      inputElement?.click();
    }, 0);
  }

  private prepareMonths() {
    this.months = DateFormatService.getCalendarLocaleSettings().monthNames.map((month, index) => ({ label: month, month: index }));
  }

  private prepareYears() {
    this.years = Array.from({ length: MAX_NUMBER_OF_YEARS }, (_, i) => this.today.getFullYear() + i);
  }

  /**
   * Calculates the state of next and previous months navigation buttons.
   * If the selected month and year are the last ones, the next month button is disabled.
   * If the selected month and year are the first ones, the previous month button is disabled.
   */
  private calculateNavigationButtonsState() {
    this.previousMonthDisabled =
      this.months.findIndex(({ month }) => month === this.selectedMonth.month) === 0 && this.years.findIndex((year) => year === this.selectedYear) === 0;

    this.nextMonthDisabled =
      this.months.findIndex(({ month }) => month === this.selectedMonth.month) === 11 &&
      this.years.findIndex((year) => year === this.selectedYear) === this.years.length - 1;
  }
}

/**
 * KLG Quotes API
 * This is KLG API to perform quote calculations and retrieve auxiliary pricing information.
 *
 * The version of the OpenAPI document: dev
 * Contact: xxx@kaplan.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplementsApiDto } from './supplements.model';
import { VacationApiDto } from './vacation.model';
import { LessonApiDto } from './lesson.model';
import { DateRangeApiDto } from './date-range.model';
import { LimitedCurrencyAmountApiDto } from './limited-currency-amount.model';
import { ItemApiDto } from './item.model';

export interface QuoteProductApiDto {
  schoolProductId?: number;
  descriptions?: Array<string>;
  includedItems?: Array<ItemApiDto>;
  selectedWeeks?: Array<DateRangeApiDto>;
  isRemovable?: boolean;
  articleDescriptions?: Array<string>;
  code?: string;
  name?: string;
  articleIds?: Array<number>;
  referenceIds?: Array<number>;
  vat?: number;
  argoServiceCode?: string;
  price?: Array<LimitedCurrencyAmountApiDto>;
  priceProvider?: number;
  endPrice?: Array<LimitedCurrencyAmountApiDto>;
  providerName?: string;
  promotionsApplied?: Array<number>;
  priceOnRequest?: boolean;
  classification?: QuoteProductApiDto.ClassificationApiDtoEnum;
  units?: number;
  startDate?: string;
  endDate?: string;
  discount?: Array<LimitedCurrencyAmountApiDto>;
  transferServiceType?: QuoteProductApiDto.TransferServiceTypeApiDtoEnum;
  transferStation?: string;
  row?: number;
  extras?: SupplementsApiDto;
  supplements?: SupplementsApiDto;
  vacations?: Array<VacationApiDto>;
  seasonVariation?: boolean;
  lesson?: LessonApiDto;
  articleName?: string;
  durationDescription?: string;
  productType?: string;
}
export namespace QuoteProductApiDto {
  export type ClassificationApiDtoEnum =
    | 'TUITION'
    | 'PRIVATE_LESSONS'
    | 'ACCOMMODATION'
    | 'VIRTUAL_INTERNSHIP'
    | 'TRANSFER'
    | 'INSURANCE'
    | 'SUPPLEMENT'
    | 'PREMIUM_ACTIVITIES'
    | 'FEE'
    | 'OTHERS'
    | 'PREMIUM_RESIDENCE'
    | 'OPT_OUT';
  export const ClassificationApiDtoEnum = {
    TUITION: 'TUITION' as ClassificationApiDtoEnum,
    PRIVATE_LESSONS: 'PRIVATE_LESSONS' as ClassificationApiDtoEnum,
    ACCOMMODATION: 'ACCOMMODATION' as ClassificationApiDtoEnum,
    VIRTUAL_INTERNSHIP: 'VIRTUAL_INTERNSHIP' as ClassificationApiDtoEnum,
    TRANSFER: 'TRANSFER' as ClassificationApiDtoEnum,
    INSURANCE: 'INSURANCE' as ClassificationApiDtoEnum,
    SUPPLEMENT: 'SUPPLEMENT' as ClassificationApiDtoEnum,
    PREMIUM_ACTIVITIES: 'PREMIUM_ACTIVITIES' as ClassificationApiDtoEnum,
    FEE: 'FEE' as ClassificationApiDtoEnum,
    OTHERS: 'OTHERS' as ClassificationApiDtoEnum,
    PREMIUM_RESIDENCE: 'PREMIUM_RESIDENCE' as ClassificationApiDtoEnum,
    OPT_OUT: 'OPT_OUT' as ClassificationApiDtoEnum,
  };
  export type TransferServiceTypeApiDtoEnum = 'ARRIVAL' | 'DEPARTURE' | 'ROUND_TRIP' | 'NO_TRANSFER';
  export const TransferServiceTypeApiDtoEnum = {
    ARRIVAL: 'ARRIVAL' as TransferServiceTypeApiDtoEnum,
    DEPARTURE: 'DEPARTURE' as TransferServiceTypeApiDtoEnum,
    ROUND_TRIP: 'ROUND_TRIP' as TransferServiceTypeApiDtoEnum,
    NO_TRANSFER: 'NO_TRANSFER' as TransferServiceTypeApiDtoEnum,
  };
}
